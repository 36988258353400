/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.fn.extend({
  lazy_select(options) {
    options = $.extend({ page_size: 25, url: "/products.json?short=1" }, options);
    return this.select2({
      ajax: {
        url() {
          if (typeof options.url === "function") {
            return options.url();
          } else {
            return options.url;
          }
        },
        dataType: "json",
        delay: 250,
        data(params) {
          return {
            search: params.term,
            // stock_mode: true
            page: params.page,
          };
        },
        processResults(data, params) {
          params.page = params.page || 1;
          return {
            results: data.results,
            pagination: { more: params.page * options.page_size < data.count },
          };
        },
        cache: true,
      },
      width: "100%",
      language: "zh-CN",
      theme: "bootstrap",
      placeholder: "请选择",
      minimumInputLength: 0,
    });
  },
});
