import stringify from "qs/lib/stringify"
import dayjs from 'dayjs';

export const productSelect2 = function (select, data) {
    select.select2({
        data: data,
        theme: "bootstrap",
        placeholder: "请选择产品",
        language: "zh-CN",
        allowClear: true,
    });
};

export const queryStringify = (params) => stringify(params, { arrayFormat: 'brackets' })


export const formatDate = (dateString) => {
    const date = dayjs(dateString);
    // Then specify how you want your dates to be formatted
    return date.format('YYYY-MM-DD HH:mm');
}